import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    webShopCategories: [],
    managers: [
      {id: 'staninvest', name: 'Staninvest d.o.o.', title:'družba za poslovanje z nepremičninami', address: 'Gregorčičeva ulica 19', post: '2000 Maribor', phone: '051 257 333', email: 'staninvest@skupina.pro', website: 'https://www.staninvest.si'},
      {
        id: 'habit',
        name: 'HABIT d.o.o.',
        title:'HABIT d.o.o.',
        website: 'https://habit.si',
        array_pe:[
          {name: 'PE VELENJE', address: 'Koroška cesta 48', post: '3320 Velenje', phone: '051 44 44 31', email: 'habit.velenje@skupina.pro'},
          {name: 'PE KOPER', address: 'Spodnje Škofije 261', post: '6281 Škofije', phone: '051 44 44 91', email: 'habit.koper@skupina.pro'},
          {name: 'PE CELJE', address: 'Ljubljanska cesta 20', post: '3000 Celje', phone: '051 44 44 61', email: 'habit.celje@skupina.pro'},
          {name: 'PE LJUBLJANA', address: 'Šmartinska cesta 52', post: '1000 Ljubljana', phone: '051 44 44 81', email: 'habit.ljubljana@skupina.pro'},
          {name: 'PE MURSKA SOBOTA', address: 'Slovenska ulica 40', post: '9000 Murska Sobota', phone: '051 44 44 39', email: 'habit.murskasobota@skupina.pro'}
        ]
      }
    ],
    real_estate_providers:[
      {
        id: 'habit',
        name: 'HABIT d.o.o.'
      },
      {
        id: 'staninvest',
        name: 'Staninvest d.o.o.'
      },
      {
        id: 'akustika group',
        name: 'Akustika group d.o.o.'
      },
      {//if the provider is empty return ''
        id: '',
        name: ''
      }
    ]
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getRealEstateProvider: (state) => (id) => {
      return state.real_estate_providers.find(y => y.id === id).name
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    }
  },
  actions: {}

}
